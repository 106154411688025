import { 
  DELETE_RECORD_ACTION,
  DELETE_RECORD_END,
  DELETE_RECORD_ERROR,
  DELETE_RECORD_OK,
  POST_CSV_DATA_ACTION, 
  POST_CSV_DATA_ERROR, 
  POST_CSV_DATA_OK, 
  POST_CSV_DATA_END, 
  CLEAR_MESSAGE,
  CONTACT_COUNT
} from "../container/Form/constants"
import { DELETE_CONTACT, INSERT_CONTACT } from "../container/UpdateContacts/constant"

export const initialState = {
  cookies: "",
  loading: false,
  deleteLoadingMessage: "",
  insertLoadingMessage: "",
  successMessage: "",
  contactMessage: "",
  contactCount: "",
  dialog: false
}

export {POST_CSV_DATA_ACTION} from '../container/Form/constants'

export const reducer = (state, action) => {
  switch(action.type) {
    case CLEAR_MESSAGE:
      console.log("Clear message")
      return {
        ...state,
        successMessage: "",
        contactMessage: ""
      }
    case CONTACT_COUNT:
      return {
        ...state,
        successMessage: "",
        contactMessage: "",
        contactCount: action.payload
      }
    case DELETE_CONTACT:
      return {
        ...state,
        loading: true,
        deleteLoadingMessage: action.payload
      }
    case DELETE_RECORD_ACTION:
      return {
        ...state,
        loading: true,
        deleteLoadingMessage: action.payload
      }
    case DELETE_RECORD_END: 
      return {
        ...state,
        loading: true
      }
    case DELETE_RECORD_ERROR:
      return {
        ...state,
        insertLoadingMessage: "",
        deleteLoadingMessage: "",
        errorMessage: action.payload,
        succeessMessage: "",
        loading: false
      }
    case DELETE_RECORD_OK:
      return {
        ...state,
        loading: false,
        deleteLoadingMessage: action.payload
      }
    case INSERT_CONTACT: 
    console.log("insert contact")
      return {
        ...state,
        loading: false,
        contactMessage: action.payload
      }
    case POST_CSV_DATA_ACTION: 
      return {
        ...state,
        loading: true,
        successMessage: "",
        insertLoadingMessage: action.payload
      }
    case POST_CSV_DATA_ERROR:
      return {
        ...state,
        loading: true
      }
    case POST_CSV_DATA_OK:
      return {
        ...state,
        insertLoadingMessage: "",
        deleteLoadingMessage: "",
        successMessage: action.payload,
        loading: false
      }
    case POST_CSV_DATA_END: 
      return {
        ...state,
        loading: true
      }
    case "SAVE_COOKIES":
      return {
        ...state,
        cookies: action.value
      }
    default: 
    return state
  }
}