import { post, get, remove } from '../../axiosCall'
import {uploadCSVEnd, uploadCSVError, contactCount} from './actions'

export const postForm = async(path, body, dispatch) => {
  try {
    const result = await post(path, body)
    if (result > 200) {
      throw new Error(result)      
    } else {
      return result
    }
  } catch (error) {
    dispatch(uploadCSVError(error))
    return error
  } finally {
    dispatch(uploadCSVEnd())
  }
}

export const deleteForm = async (path, body, dispatch) => {
  const result = await post(path, body)
  return result
}

export const deleteSched = async (path, body, dispatch) => {
  const result = await remove(path, body)
  return result
}

export const getID = async (path, body, dispatch) => {
  const result = await get(path, body)
  return result.data
}

export const checkContact = async (path, body, dispatch) => {
  const result = await get(path, body)
  return result.data.count
}