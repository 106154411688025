import React, {useState, useEffect} from 'react'
import Spinner from '../../component/Spinner'
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Grid,
  TextField
} from '@material-ui/core'
import { useStateValue } from '../../stateContext'
import { authenticate } from './requestApi'
// import { 
//   closeDialog, 
//   setPasswordAction,
//   setSessionAction,
//   setUsernameAction
// } from './action'




const Login = (props) => {
  const [message, setMessage] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  
  const session = window.sessionStorage.getItem("ci_cookies")
  const [{cookies}, dispatch] = useStateValue()


  const onSubmit = async (username, password, dispatch) => {
      const credentials = {
        method: 'post',
        j_username: username,
        j_password: password
      }
      const result = await authenticate('auth/login/', credentials)
      if (result.status === 200) {
        dispatch({ type: "SAVE_COOKIES", value: result.data })
        window.sessionStorage.setItem("ci_cookies", JSON.stringify(result.data))
      }
      setMessage(result)
  }


  const onChangeHandle = (e) => {
    setMessage("")
    if (e.target.id === 'username') {
      setUsername(e.target.value)
    } else {
      setPassword(e.target.value)
    }
  }

  return (
    <div>
      <Dialog aria-labelledby="Login dialog box" open={!!cookies || !!session ? false : true} fullWidth={true} maxWidth="xs">
        <DialogTitle>Sign in</DialogTitle>
        <Grid item xs={12}>
          <center>
            <p style={{color: 'red'}}>
              {!!message.status && message.status === 300 ? "Failed" : ""}
            </p>
          </center>
        </Grid>
        <DialogContent>
            <Grid item>
              <TextField label="Login" id="username" type="text" onChange={(e) => {onChangeHandle(e, dispatch)}} fullWidth/>
            </Grid>
            <Grid item>
              <TextField label="Password" id="password" type="password" onChange={(e) => {onChangeHandle(e, dispatch)}} fullWidth/>
            </Grid>
        </DialogContent>
        <DialogActions className="dialog-action">
          <Button variant="contained" color="primary" id="login" onClick={() => onSubmit(username, password, dispatch)} fullWidth>Login</Button>
        </DialogActions>

      </Dialog>
    </div>
  )
}

export default Login
