import React from 'react'
import {Toolbar, Typography, Button } from '@material-ui/core'
import {Link} from 'react-router-dom'
import { useStateValue } from '../../stateContext'
import './index.scss'

const Header = () => {
  const session = window.sessionStorage.getItem('ci_cookies')
  const [{cookies}, dispatch] = useStateValue()

  const onHandleLogout = () => {
    window.sessionStorage.removeItem("ci_cookies")
    dispatch({type: "SAVE_COOKIES", value: ""})
  }

  return (
    <Toolbar style={{backgroundColor: "#FFF"}}>
      <Typography variant="h6">
          <img  height="70px" src="https://logodix.com/logo/1961524.png" alt="sample logo"/>
      </Typography>
      { !!session || !!cookies ?
        <div>
          <Link to="/"><Button>Form</Button></Link>
          <Link to="/update-contacts"><Button>Contacts</Button></Link>
          <Button style={{marginLeft: 'auto'}}color="secondary" onClick={() => onHandleLogout()}>
            Logout
          </Button>
        </div>  
      : "" }
    </Toolbar>
  )
}

export default Header