import {DELETE_CONTACT, INSERT_CONTACT} from './constant'

export const deleteContact = (payload) => {
  return {
    type: DELETE_CONTACT,
    payload
  }
}

export const insertContact = (payload) => {
  console.log({payload})
  return {
    type: INSERT_CONTACT,
    payload
  }
}
