/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useCallback, useState } from 'react'
import { Paper, Grid, Button } from '@material-ui/core'
import {useDropzone} from 'react-dropzone'
import { csvSeparator } from '../../helper'
import { clearOldContanct, inserContact } from './requestApi'
import { useStateValue } from '../../stateContext'
import Spinner from '../../component/Spinner'
import { deleteContact, insertContact } from './action'
import { clearMessage } from '../Form/actions'

const UpdateContacts = () => {
  const [filename, setFilename] = useState("")
  const [users, setUsers] = useState([])
  const [{loading, deleteLoadingMessage, contactMessage, errorMessage}, dispatch] = useStateValue()

  const onDrop = useCallback(acceptedFiles => {
    const data = new FormData();
    dispatch(clearMessage())
    data.append('file', acceptedFiles[0])
    data.append('name', 'csv file')
    data.append('description', 'testing')
    setFilename(acceptedFiles[0].name)
    let reader = new FileReader()
    reader.readAsText(acceptedFiles[0])
    reader.onload = loadHeader
  }, [])

  const loadHeader = (e) => {
    const {target} = e
    const erm = []
    const data = csvSeparator(target.result, ",") || []
    data.map((e,k) => {
      const er =  {
        contID: "",
        empID: "",
      }
      if (k > 0) {
        e.map((i, x) => {
          if (x === 0) {
            er.contID = i
          } if (x === 1) {
            er.empID = i
          } 
        })
        erm.push(er)
      }
    })

    setUsers(erm)
  }

  const saveUser = async () => {
    const clearingResult = await clearOldContanct('clear-existing-contacts', "") 
    dispatch(deleteContact(clearingResult.message))
    if (clearingResult.status === 200) {
      const insertResult = await inserContact('insert-contacts', users)
      if (insertResult.status === 200) {
        console.log(insertResult.message)
        dispatch(insertContact(insertResult.message))
      }
    }
  }

  console.log({contactMessage})

  const {getInputProps} = useDropzone({onDrop, accept: ".csv"})
  return (<center>
    <Paper style={{ width: '80%', padding: 30, marginTop: 10,  textAlign: "center", marginBottom: 10 }} elevation={3}>
      <Spinner loading={loading} message1={deleteLoadingMessage} />
      <Grid container spacing={3} alignItems="center" alignContent="center" justify="center">
        <p style={{color: "green"}}>
          {contactMessage || ""}
        </p>
        <p style={{color: "red"}}>
          {errorMessage || ""}
        </p>
        <Grid item xs={12}>
          <input
            {...getInputProps()}
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
          />
          <label htmlFor="raised-button-file">
            <Button  variant="contained" color="primary" component="span">
              Update Contacts
            </Button>
            </label> 
          <p>{filename}</p>
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" color="primary" onClick={() => {saveUser()}}>Submit</Button>
        </Grid>
      </Grid>
    </Paper> 
  </center>)
}

export default UpdateContacts