import {
  CLEAR_MESSAGE,
  DELETE_RECORD_ACTION,
  DELETE_RECORD_END,
  DELETE_RECORD_ERROR,
  DELETE_RECORD_OK,
  POST_CSV_DATA_ACTION,
  POST_CSV_DATA_END,
  POST_CSV_DATA_OK,
  POST_CSV_DATA_ERROR,
  CONTACT_COUNT
} from './constants'


export const uploadCSVAction = (payload) => {
  return {
    type: POST_CSV_DATA_ACTION,
    payload
  }
}

export const uploadCSVEnd = () => {
  return {
    type: POST_CSV_DATA_END,
  }
}

export const uploadCSVError = (payload) => {
  return {
    type: POST_CSV_DATA_ERROR,
    payload
  }
}

export const uploadCSVOk = (payload) => {
  return {
    type: POST_CSV_DATA_OK,
    payload
  }
}

export const deleteRecordAction = (payload) => {
  return {
    type: DELETE_RECORD_ACTION,
    payload
  }
}

export const deleteRecordEnd = () => {
  return {
    type: DELETE_RECORD_END,
  }
}


export const deleteRecordError = (payload) => {
  console.log("dispatch delete error")
  return {
    type: DELETE_RECORD_ERROR,
    payload
  }
}

export const deleteRecordOk = (payload) => {
  return {
    type: DELETE_RECORD_OK,
    payload
  }
}

export const clearMessage = () => {
  return {
    type: CLEAR_MESSAGE
  }
}

export const contactCount = (payload) => {
  return {
    type: CONTACT_COUNT,
    payload
  }
}


