export const POST_CSV_DATA_ACTION = 'app/container/Form/constant/POST_CSV_DATA_ACTION'
export const POST_CSV_DATA_END = 'app/container/Form/constant/POST_CSV_DATA_END'
export const POST_CSV_DATA_OK = 'app/container/Form/constant/POST_CSV_DATA_OK'
export const POST_CSV_DATA_ERROR = 'app/container/Form/constant/POST_CSV_DATA_ERROR'

export const CONTACT_COUNT = 'app/container/Form/constant/CONTACT_COUNT'
export const CLEAR_MESSAGE = 'app/container/Form/constant/CLEAR_MESSAGE'

export const DELETE_RECORD_ACTION = 'app/container/Form/constant/DELETE_RECORD_ACTION'
export const DELETE_RECORD_END = 'app/container/Form/constant/DELETE_RECORD_END'
export const DELETE_RECORD_ERROR = 'app/container/Form/constant/DELETE_RECORD_ERROR'
export const DELETE_RECORD_OK = 'app/container/Form/constant/DELETE_RECORD_OK'