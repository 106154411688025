import { post, remove} from '../../axiosCall'


export const clearOldContanct = async (path, payload, dispatch) => {
  const result = await remove(path, payload)
  if (result.data.status === 200) {
    return result.data
  } else {
    return false
  }
}

export const inserContact = async (path, payload) => {
  const result = await post(path, payload)
  console.log(result.data)
  if (result.data.status === 200) {
    return result.data
  } else {
    return result.data
  }
}